.table-cards .hidden-cards {
  grid-row: 1;
  grid-column: 1;
}
.table-cards .visible-cards {
  grid-row: 1;
  grid-column: 1;
}
.table-cards-container {
  display: grid;
}
